import { v4 as uuidv4 } from 'uuid';
import { FloTypes, Legacy, VRP } from '@flo-concepts/flo-types';
import { cloneDeep, isEqual, sortBy, uniqBy as uniqueBy } from 'lodash';
import {
    mapOrderPlaceToCompound,
    ALL_ZONES_LABEL,
    NoResource,
} from '@components/Main/Container/Main.helper';
import {
    ZoneWithOrders,
    FloTemplateForSession,
    State,
    SystemDefaultObject,
} from '@components/Main/Container/MainReducer.model';
import {
    CreatorResultRow,
    DQRule,
    ResourceFormResultRow,
    SessionCreatorStatusTypes,
    SessionOperationConfig,
    StatusBadgeType,
} from './SessionCreator.model';

export const initialWaypointValue: VRP.Hydrated.Waypoint = {
    id: '',
    name: '',
    location: {
        latitude: null,
        longitude: null,
        waypointID: '',
    },
    customers: [] as Array<VRP.Hydrated.Customer>,
};

export const initialOperationFlags = {
    FH: true,
    BH: false,
    D2D: false,
};

export const prepareDefaultWizardValues = (
    systemDefaults: State['systemDefaults'],
    templateForSession: FloTemplateForSession
) => {
    const templateRunSolver = templateForSession?.templateBody[0]?.runSolver;
    const templateOperationFlags =
        templateForSession?.templateBody[0]?.operationFlags;
    const templateOptions = templateForSession?.templateOptions || [];

    const { sessionWizard: wizardDefaults } =
        (systemDefaults as SystemDefaultObject<
            SystemDefaultObject<unknown>
        >) || { sessionWizard: false };

    const runSolver = (wizardDefaults as SystemDefaultObject<boolean>)
        ?.runSolver;

    const mergeResources =
        templateOptions.find((opt) => opt.key === 'mergeResources')?.value ??
        (wizardDefaults as SystemDefaultObject<boolean>)?.mergeResources ??
        false;

    return {
        runSolver: templateRunSolver ?? runSolver ?? false,
        operationFlags: templateOperationFlags ?? initialOperationFlags,
        mergeResources,
    };
};

export const createCompoundsOfOrders = (
    orders: Array<Legacy.Order>,
    zones: Array<{
        compound: string;
        zones: Array<{
            zone: string;
            orders: Array<Legacy.Order>;
            order_number: number;
        }>;
        order_number: number;
    }>
) => {
    if (!orders?.length) return [];

    const noZonesOrdersCount: Record<string, number> = {};

    orders.forEach(({ to, toCode }) => {
        const isZone = zones.find((z) => z.compound === to);
        if (!isZone)
            noZonesOrdersCount[toCode] = (noZonesOrdersCount[toCode] || 0) + 1;
    });

    const zonesMap = zones.map((c) => ({
        compound: c.compound,
        zones: c.zones.map((z) => ({
            zone: z.zone,
            orderNumber: z.order_number,
            orders: z.orders,
        })),
        orderNumber: c.order_number,
    }));

    return sortBy(
        uniqueBy(
            [
                ...orders.map((order) => {
                    const compound = zonesMap.find(
                        (z: { compound: string }) => z.compound === order.from
                    );

                    if (compound)
                        return mapOrderPlaceToCompound(
                            order.from,
                            order.fromCode,
                            'compound',
                            'from',
                            compound.orderNumber,
                            compound.zones as unknown as Array<ZoneWithOrders>
                        );

                    return null;
                }),
                ...orders.map((order) => {
                    const compound = zonesMap.find(
                        (z: { compound: string }) => z.compound === order.to
                    );

                    if (compound)
                        return mapOrderPlaceToCompound(
                            order.to,
                            order.toCode,
                            // TODO: wtf?
                            'compound',
                            'to',
                            compound.orderNumber,
                            compound.zones as unknown as Array<ZoneWithOrders>
                        );

                    return null;
                }),
            ].filter((c) => c),
            'placeID'
        ),
        'name'
    );
};

export const createSessionFromOrdersRules = (payload: Array<string>) => [
    {
        id: uuidv4(),
        name: 'FILTER_BY_ZONES',
        config: initialOperationFlags,
        enabled: false,
        payload: [] as Array<string>,
    },
    {
        id: uuidv4(),
        name: 'FILTER_BY_PLACE',
        enabled: true,
        payload: [] as Array<string>,
    },
    {
        id: uuidv4(),
        name: 'FILTER_BY_ORDER',
        enabled: true,
        payload,
    },
];

export const createSessionRules = (
    rulesConfig: SessionOperationConfig,
    compound: CreatorResultRow
) => {
    const destinationZones = compound.destinations.zones;
    const allZones = destinationZones.includes(ALL_ZONES_LABEL);

    return [
        {
            id: uuidv4(),
            name: 'FILTER_BY_ZONES',
            config: rulesConfig,
            enabled: !allZones,
            payload: allZones
                ? []
                : destinationZones.reduce((acc, curr) => {
                      return [...acc, `${compound.source.id}|${curr}`];
                  }, []),
        },
        {
            id: uuidv4(),
            name: 'FILTER_BY_PLACE',
            config: rulesConfig,
            enabled: allZones,
            payload: [compound.source.id],
        },
    ];
};

export const createSessionDescription = (
    compound: CreatorResultRow,
    sessionOperationsDescription?: string
) => {
    return `${compound.source.name}: ${
        compound.destinations?.description
            ? compound.destinations?.description
            : compound.destinations?.zones.join(', ')
    }${
        sessionOperationsDescription ? ` (${sessionOperationsDescription})` : ''
    }`;
};

export const createOperationsDescription = (
    rulesConfig: SessionOperationConfig
) => {
    return Object.keys(rulesConfig)
        .filter(
            (k) => k !== 'FH' && rulesConfig[k as keyof SessionOperationConfig]
        )
        .join('/');
};

export const createSessionPayload = (
    results: Array<CreatorResultRow | ResourceFormResultRow>,
    dqRules: Array<DQRule>,
    createdBy: string,
    sessionDescription?: string,
    createFromOrders?: Array<string>
): Array<FloTypes.Session> => {
    const isCreatedFromOrders = createFromOrders?.length > 0;
    const payloadResult = results.reduce((acc, compound) => {
        const rulesConfig: SessionOperationConfig =
            compound.operationFlags ?? initialOperationFlags;
        const sessionOperationsDescription =
            createOperationsDescription(rulesConfig);

        const dqRulesConfig = dqRules.reduce((acc, rule) => {
            return rule.enabled &&
                ![
                    'FILTER_BY_ZONES',
                    'FILTER_BY_ORDER',
                    'FILTER_BY_PLACE',
                ].includes(rule.ruleName)
                ? [
                      ...acc,
                      {
                          id: uuidv4(),
                          name: rule.ruleName,
                          enabled: true,
                          payload: rule.payload,
                      },
                  ]
                : acc;
        }, []);

        const sessionRules = !isCreatedFromOrders
            ? createSessionRules(rulesConfig, compound as CreatorResultRow)
            : createSessionFromOrdersRules(createFromOrders);

        const getResourceStartWaypoint = (
            origin: FloTypes.Place,
            startWaypoint?: VRP.Hydrated.Waypoint
        ): VRP.Hydrated.Waypoint => {
            const init = (waypoint: VRP.Hydrated.Waypoint): boolean => {
                return !(
                    isEqual(initialWaypointValue, waypoint) ||
                    !waypoint?.id ||
                    !waypoint?.name ||
                    !waypoint?.location?.latitude ||
                    !waypoint?.location?.longitude
                );
            };

            if (init(startWaypoint)) {
                return startWaypoint;
            }

            return {
                id: origin?.placeID,
                name: origin?.name,
                location: origin
                    ? cloneDeep(origin.location)
                    : cloneDeep(initialWaypointValue.location),
                customers: [
                    {
                        place: origin ? cloneDeep(origin) : null,
                    },
                ],
            };
        };

        const sessionResult = {
            sessionID: uuidv4(),
            description:
                sessionDescription ??
                createSessionDescription(
                    compound as CreatorResultRow,
                    sessionOperationsDescription
                ),
            params: {
                runIterationOnCreate: compound.runSolver ?? false,
                rules: [...sessionRules, ...dqRulesConfig],
            },
            orders: [] as Array<FloTypes.Hydrated.Order>,
            resources: (isCreatedFromOrders
                ? [compound.resource]
                : compound.resources
            )
                .reduce(
                    (rcs, { id, name, type, origin, start, end, amount }) => [
                        ...rcs,
                        ...Array(amount).fill({
                            id,
                            name,
                            type,
                            origin,
                            start: {
                                time: start?.time,
                                waypoint: getResourceStartWaypoint(
                                    origin,
                                    start?.waypoint
                                ),
                            },
                            end,
                        }),
                    ],
                    []
                )
                .map((res) => ({ ...res, id: uuidv4() }))
                .filter((res) => res.type.id !== NoResource.id),
            initRoutes: [] as Array<VRP.Hydrated.Load>,
            creator: 'HUMAN',
            createdBy,
            recordStatus: 'NEW',
        };

        return [...acc, ...[sessionResult]];
    }, []);

    return payloadResult;
};

export const combineIntoSession = (
    payloads: Array<FloTypes.Session>
): Array<FloTypes.Session> => {
    if (payloads.length < 2) return payloads;

    const isCreateFromOrders =
        payloads[0].params?.rules?.some((r) => r.name === 'FILTER_BY_ORDER') ||
        false;

    const combinedDescription = isCreateFromOrders
        ? payloads[0].description
        : payloads.map((sess) => sess.description).join(' & ');

    const generateCombinedRules = (ruleName: string) => {
        const hasRule = payloads.some((payload) => {
            return payload.params.rules.some(
                (rule) => rule.name === ruleName && rule.enabled
            );
        });
        return {
            id: '',
            name: ruleName,
            enabled: hasRule,
            payload: payloads
                .filter((p) =>
                    p.params.rules.some(
                        (rule) => rule.name === ruleName && rule.enabled
                    )
                )
                .flatMap((p) => {
                    return p.params.rules.flatMap((rule) => rule.payload);
                })
                .filter((r) => !!r),
        };
    };

    const combinedParams = {
        runIterationOnCreate:
            payloads[0]?.params?.runIterationOnCreate ?? false,
        rules: isCreateFromOrders
            ? payloads[0].params.rules
            : ['FILTER_BY_ZONES', 'FILTER_BY_PLACE'].map(generateCombinedRules),
    };

    const combinedResources = payloads.flatMap((sess) => sess.resources);
    return [
        {
            ...payloads[0],
            description: combinedDescription,
            params: combinedParams,
            resources: combinedResources,
        },
    ];
};

export const processSessions = async (
    sessions: Array<FloTypes.Session>,
    processSingleSession: (sessionSetup: FloTypes.Session) => Promise<boolean>,
    refreshSessions: () => Promise<void>
) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const sess of sessions) {
        try {
            // eslint-disable-next-line no-await-in-loop
            await processSingleSession(sess);
        } catch (e) {
            console.error(e);
        }
    }

    await refreshSessions();
};

export const prepareStatusBadge = { color: 'secondary', message: 'Preparing' };

export const getStatusBadgeDescription = (
    currentSessionStatus: boolean | undefined
): StatusBadgeType => {
    if (typeof currentSessionStatus !== 'boolean')
        return { color: 'info', message: 'In progress' };

    return {
        color: currentSessionStatus ? 'success' : 'warning',
        message: currentSessionStatus ? 'Created' : 'Failed',
    };
};

export const getProgressBarColor = (
    progressPercentage: number,
    sessionFailed: boolean
): SessionCreatorStatusTypes => {
    if (progressPercentage < 100) return 'info';
    if (sessionFailed) return 'warning';
    return 'success';
};

export const createWaypointFromPlace = (
    place: FloTypes.Place
): VRP.Hydrated.Waypoint => {
    return {
        id: place.placeID,
        name: place.name,
        location: {
            waypointID: place.location.waypointID,
            latitude: place.location.latitude,
            longitude: place.location.longitude,
        },
        customers: [{ place }],
    };
};

export const getWaypointForTransportResource = (
    places: Array<FloTypes.Place>,
    sourceID: string
): VRP.Hydrated.Waypoint => {
    const place = places.find((p) => p.placeID === sourceID);

    if (place) {
        return {
            id: place.placeID,
            name: place.name,
            location: {
                waypointID: place.location.waypointID,
                latitude: place.location.latitude,
                longitude: place.location.longitude,
            },
            customers: [{ place }],
        };
    }
    return cloneDeep(initialWaypointValue);
};

export const isoDateFormatter = (dateString?: string): string => {
    try {
        return (dateString ? new Date(dateString) : new Date())
            .toISOString()
            .split('T')?.[0];
    } catch (err) {
        return undefined;
    }
};

export const dateTimeFormatter = <T extends string | undefined>(
    dateString: T
): T => dateString?.replace(/T/, ' ') as T;
