import { useLocalObservable } from 'mobx-react-lite';
import { useApiContext } from '../apiclient';

export default () => {
    const apiClient = useApiContext();
    const store = useLocalObservable(() => ({
        lastSync: {
            finishedAt: '',
            id: '',
            startedAt: '',
            startedBy: '',
            status: 'Unknown',
        },
        interval: false,
        qualityReport: {},
        qualityReportLoading: false,
        getDataFromNVD: async ({ email }) => {
            await apiClient.getOrdersForCustomer({ email });
            store.getSyncStatus();
        },
        startInterval: async () => {
            await store.getSyncStatus();
            store.interval = setInterval(async () => {
                await store.getSyncStatus();
            }, 10000);
        },
        stopInterval: () => {
            clearInterval(store.interval);
        },
        getSyncStatus: async () => {
            const data = await apiClient.getSyncStatus();
            store.lastSync = data.data;
        },
        getQualityReport: async () => {
            store.qualityReportLoading = true;

            try {
                store.qualityReport = await apiClient.getDataQualityReport();
            } catch (error) {
                // TODO: introduce logger
            }
            store.qualityReportLoading = false;
        },
        resetDataQualityReport: () => {
            store.qualityReport = {};
        },
    }));
    return store;
};
